import { title } from 'assets/jss/material-kit-react.js'

const productStyle = theme => ({
  section: {
    textAlign: 'center',
  },
  title: {
    ...title,
    paddingTop: '10px',
    textDecorationColor: '#ef3d56',
    textAlign: 'left'
  },
  description: {
    color: '#999',
  },
  root: {
    boxShadow: 'none'
  },
  responsiveCardMedia: {
    width: '100%',
    height: '200px',
    position: 'relative',
  
    [theme.breakpoints.up('md')]: {
      height: '400px',
    }
  },
  overlayImageStyle: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    opacity: '0.5',
  },
  sider: {
    height:'200px',
    boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)'
  }
})

export default productStyle
